<template>
  <section>
    <div class="all-media">
      <div>
        <b-card
          no-body
          style="margin-top: 3rem"
        >
          <div class="m-2">
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-50 mr-1"
                  @input="paginationChanged(currentPage = 1)"
                />
                <label>Media Storage</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                    @keyup.enter="paginationChanged(1)"
                    @change="currentPage = 1"
                  />
                  <b-button
                    variant="primary"
                    @click="paginationChanged(1)"
                  >
                    Search
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-skeleton-table
            v-if="loading"
            :rows="perPage"
            :columns="5"
            :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
          />
          <b-table
            v-else
            ref="mediaTable"
            :items="allUserMediaStorage"
            :per-page="perPage"
            hover
            selectable
            :select-mode="selectMode"
            responsive
            :fields="tableColumns"
            show-empty
            empty-text="No matching records found"
            class="position-relative"
            @row-selected="onRowSelected"
          >
            <!-- Column: Media -->
            <template #cell(MEDIA)="data">
              <b-media vertical-align="center">
                <template
                  #aside
                >
                  <div>
                    <b-img
                      class="userMediaImage"
                      :src="getThumbnailOfFile(data.item)"
                    />
                  </div>
                </template>
                <b-link>
                  <span class="text-nowrap">
                    {{ data.item.name }}
                  </span>
                </b-link>
              </b-media>
            </template>

            <!-- Column: Size -->
            <template #cell(SIZE)="data">
              <span class="text-nowrap">
                {{ Math.ceil(data.item.size) }} Mb
              </span>
            </template>

            <!-- Column: Type -->
            <template #cell(TYPE)="data">
              {{ data.item.type }}
            </template>
            <!-- Column: Imported From -->
            <template
              #cell(DURATION)="data"
              class="d-flex justify-content-center align-items-center"
            >
              {{ data.item.duration | convertSecToMin }}
            </template>

            <!-- Column: Actions -->
            <template #cell(ACTIONS)="data">
              <div class="text-nowrap">
                <feather-icon
                  :id="`invoice-row-${data.item.id}-send-icon`"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  icon="TrashIcon"
                  class="cursor-pointer float-center ml-4"
                  size="16"
                  @click="deleteModal(data.item.id, $event)"
                />
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
              >
                <span
                  class="text-muted"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
              >
                <b-pagination
                  :value="currentPage"
                  :total-rows="totalRecords"
                  :per-page="perPage"
                  class="mb-0 mt-1 mt-sm-0"
                  aria-controls="media-table"
                  size="sm"
                  pills
                  @change="paginationChanged"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BImg,
  BLink,
  BPagination,
  VBToggle,
  BSkeletonTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import { required } from '@validations'
import 'vuesax/dist/vuesax.css'
import moment from 'moment'
import MediaType from '@/common/enums/mediaTypeEnum'
import { getThumbnailOfFile } from '@/common/global/functions'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BImg,
    BLink,
    BPagination,
    vSelect,
    BSkeletonTable,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  filters: {
    convertSecToMin(value) {
      if (!value) return '--'
      return moment.utc(value * 1000).format('mm:ss')
    },
  },

  data() {
    return {
      MediaType,
      loading: false,
      index: null,
      deleteMediaId: null,
      allUserMediaStorage: [],
      option: [{ title: 'Video' }, { title: 'Image' }],
      currentPage: 1,
      perPage: 4,
      totalRecords: 0,
      perPageOptions: [3, 5, 8, 10],
      searchQuery: '',
      tableColumns: [
        { key: 'MEDIA', thClass: 'pull-left text-left' },
        { key: 'SIZE' },
        { key: 'TYPE' },
        { key: 'DURATION' },
        { key: 'ACTIONS', thClass: 'pull-right text-right' },
      ],
      selectMode: 'single',
      // validation
      required,
    }
  },
  mounted() {
    this.getAllMediaStorageOfUser()
  },
  methods: {
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    async paginationChanged(offset) {
      this.currentPage = offset
      await this.getAllMediaStorageOfUser()
    },
    getAllMediaStorageOfUser() {
      this.loading = true
      this.$axios
        .get(`admin/media/${(this.$route.params.userId)}?offset=${this.currentPage - 1}&limit=${this.perPage}&search_query=${this.searchQuery}`)
        .then(({ data: { data } }) => {
          if (this.currentPage > 1 && data.results.length === 0) {
            this.paginationChanged(this.currentPage - 1)
            return
          }
          this.allUserMediaStorage = data.results
          this.totalRecords = data.total
          this.loading = false
        }).catch(error => ({ error }))
    },
    addMediaButton() {
      this.$refs['add-media-modal'].show()
    },
    async onRowSelected(items) {
      if (items) {
        const mediaArray = Object.assign([], items)
        await this.$store.dispatch('lightBox/lightBoxData', mediaArray)
      }
      this.$refs.mediaTable.clearSelected()
    },
    deleteModal(id, e) {
      e.preventDefault()
      e.stopPropagation()
      this.deleteMediaId = id
    },
  },
}
</script>

<style lang="scss" scoped>
.all-media{
  .card{
    text-align: center
  }
  .media-aside{
    margin-right: 0px
  }
  .media-body{
    display: inherit;
    padding-left: 10px;
  }
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.circular_image {
  border-radius: 40px;
  background-color: #eeedfd;
}
.button{
  width: 300px;
  height: auto;
  border-radius: 5px !important;
}
.userMediaImage{
  width: 20px;
  height: 20px;
}
.skeleton-loading-table{
  th, td{
    padding: 1rem 2rem !important;
    height: 3.1rem !important;
    .b-skeleton-text{
      height: 1.2rem;
      margin-bottom: 0;
    }
  }
}
</style>
